<template>
  <div class="my-2">
    <template v-if="selectedFlag">
      <div class="cursor-pointer vs-con-loading__container" id="cancel-btn-loading">
        <div class="available-action flex px-4 py-2" @click="confirmCancel()">
          <feather-icon icon="XCircleIcon" svgClasses="w-4 h-4" class="self-center"/>
          <span class="text-sm ml-2">{{ $t('Cancel Order') }}</span>
        </div>
      </div>
    </template>
    <template v-else>
      <vx-tooltip position="bottom" color="warning" :text="`${$t('Please select only the created order you want to cancel!')}`">
        <div class="cursor-not-allowed">
          <div class="pointer-events-none unavailable-action flex px-4 py-2">
            <feather-icon icon="XCircleIcon" svgClasses="w-4 h-4" class="self-center"/>
            <span class="text-sm ml-2">{{ $t('Cancel Order') }}</span>
          </div>
        </div>
      </vx-tooltip>
    </template>
  </div>
</template>

<script>
import {sendRequest} from '../../../http/axios/requestHelper.js'
import i18nData from '../../../i18n/i18nData.js'
export default {
  props: ['selected'],
  data () {
    return {
      selectedFlag: false
    }
  },
  watch: {
    selected (val) {
      if (this.$route.name === 'merchant-fulfillment-outbound-orders') {
        if (val.length !== 0 && val.filter(item => item.status === 'confirmed_to_ship' || item.status === 'created').length !== 0) {
          this.selectedFlag = true
        } else {
          this.selectedFlag = false
        }
      } else if (val.length !== 0 && val.filter(item => item.status === 'created' || item.status === 'pickup_requested' || item.status === 'pickup_rescheduled').length !== 0) {
        this.selectedFlag = true
      } else {
        this.selectedFlag = false
      }
    }
  },
  methods: {
    confirmCancel () {
      this.$vs.dialog({
        color: 'danger',
        title: 'Confirm',
        text: i18nData[this.$i18n.locale]['Are you sure to cancel these orders?'],
        accept: this.$route.name === 'merchant-fulfillment-outbound-orders' ? this.cancelOrdersFulfillment : this.cancelOrders
      })
    },
    cancelOrders () {
      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: '#cancel-btn-loading',
        scale: 0.45
      })
      const trackings  = []
      this.selected.forEach(order => {
        if (order.status === 'created' || order.status === 'pickup_requested' || order.status === 'pickup_rescheduled') {
          trackings.push({
            order: order.id,
            packages: order.packages.map(item => item.package.id),
            status: 'cancelled'
          })
        }
      })
      sendRequest(true, false, this, 'api/v1/tracking/', 'post', trackings, true,
        () => {
          this.$emit('loadShipments')
          this.$emit('selected', [])
          this.$vs.loading.close('#cancel-btn-loading > .con-vs-loading')
        }
      )
    },
    cancelOrdersFulfillment () {
      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: '#cancel-btn-loading',
        scale: 0.45
      })
      const trackings  = []
      this.selected.forEach(order => {
        if (this.$route.name === 'merchant-fulfillment-outbound-orders' && (order.status === 'created' || order.status === 'confirmed_to_ship')) {
          trackings.push({
            order: order.id,
            status: 'cancelled'
          })
        }
      })
      sendRequest(true, false, this,  `api/v1/fc/orders/${this.selected[0].id}/tracking/`, 'post', trackings, true,
        () => {
          this.$emit('loadShipments')
          this.$emit('selected', [])
          this.$vs.loading.close('#cancel-btn-loading > .con-vs-loading')
        }
      )
    }
  }
}
</script> 